import * as actionType from '../../utils/constants/actionType';

const initialState = {
  contacts: {}
};

const contact = (state = initialState, action) => {
  switch (action.type) {
    case actionType.FETCH_CONTACT:
      return { ...state, contacts: action.payload };
    default:
      return state;
  }
};

export default contact;
