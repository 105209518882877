// common
export const TOKEN = 'token';
export const REFRESH_TOKEN = 'refresh_token';
export const EXPIRED_REFRESH_TOKEN_TIME = 30 * 24 * 60 * 60 * 1000;
export const LANG_KEY = 'vsm-lang';

// export const BASE_URL = 'https://solutions.vsmart.net';
export const BASE_URL = `${window.location.origin}`;

// routes
export const routes = {
  ERROR: '/error',
  LOGIN: '/login',
  LOGOUT: '/logout',
  CHANGE_PASSWORD: '/change-password',
  DASHBOARD: '/dashboard',
  PRODUCT: '/product',
  SOLUTION: '/solution',
  SOLUTION_DETAIL: '/solution-detail/:detail',
  CONTACT: '/contact',
  POLICY: '/policy',
  TERM: '/term',
  VDMS: '/vdms',
};
export const optionClean = {
  allowedTags: [
    'h3',
    'h4',
    'h5',
    'h6',
    'blockquote',
    'p',
    'a',
    'ul',
    'ol',
    'nl',
    'li',
    'b',
    'i',
    'strong',
    'em',
    'strike',
    'code',
    'hr',
    'br',
    'div',
    'table',
    'thead',
    'caption',
    'tbody',
    'tr',
    'th',
    'td',
    'pre',
    'iframe',
    'img',
  ],
  allowedAttributes: {
    a: ['href', 'name', 'target'],
    img: ['src'],
  },
};
