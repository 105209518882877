import React from 'react';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import './Layout.scss';
import { routes } from '../../utils/constants/constants';
import * as icons from '../../assets';

const Footer = () => {
  // const handleFollow = () => {
  //   window.open('https://www.facebook.com/congtyVINSMART/?ref=br_rs', '_blank');
  // };
  const handleTerm = type => {
    window.open(`#${type}`, '_blank');
  };
  return (
    <div className="footer-container">
      <span className="footer-left">
        <div
          className="privacy"
          onClick={() => handleTerm(routes.POLICY)}
          style={{ cursor: 'pointer' }}
        >
          <FormattedMessage id="IDS_SUPPORT_PRIVACY" />
        </div>
        {/* <div
          onClick={() => handleTerm(routes.TERM)}
          style={{ cursor: 'pointer' }}
        >
          <FormattedMessage id="IDS_SUPPORT_TERM_CONDITION" />
        </div> */}
      </span>
      <span className="footer-right-mobile">
        <img
          src={icons.ic_facebook}
          alt="facebook"
          style={{ cursor: 'pointer' }}
          onClick={() =>
            window.open(
              'https://www.facebook.com/congtyVINSMART/?ref=br_rs',
              '_blank'
            )
          }
        />
        {/* <img
          src={icons.ic_youtube}
          alt="youtube"
          style={{ cursor: 'pointer', marginLeft: 20 }}
          onClick={() =>
            window.open(
              'https://www.youtube.com/channel/UC9IWkpI_XA4CS8Y9dnC6KrA',
              '_blank'
            )
          }
        /> */}
      </span>

      <p className="footer-center">
        <FormattedMessage
          id="IDS_SUPPORT_COPYRIGHT"
          // values={{ year: '2018' }}
          values={{ year: new Date().getFullYear() }}
        />
      </p>
      <span className="footer-right">
        <img
          src={icons.ic_facebook}
          alt="facebook"
          style={{ cursor: 'pointer' }}
          onClick={() =>
            window.open(
              'https://www.facebook.com/congtyVINSMART/?ref=br_rs',
              '_blank'
            )
          }
        />
        {/* <img
          src={icons.ic_youtube}
          alt="youtube"
          style={{ cursor: 'pointer', marginLeft: 20 }}
          onClick={() =>
            window.open(
              'https://www.youtube.com/channel/UC9IWkpI_XA4CS8Y9dnC6KrA',
              '_blank'
            )
          }
        /> */}
      </span>
    </div>
  );
};

export default withRouter(Footer);
