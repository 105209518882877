import React, { useEffect, useState, useRef, Fragment } from 'react';
import { connect } from 'react-redux';
import './Solution.scss';
import SolutionItem from './SolutionItem';
import { actionFetchSolution } from './SolutionAction';
import { isEmpty } from '../../utils/helpers/helpers';
import Header from '../../components/layout/Header';
import TabMenu from '../../components/layout/TabMenu';
import Footer from '../../components/layout/Footer';
import SubFooter from '../../components/layout/SubFooter';
import { actionFetchBanner, actionActiveScroll } from '../system/systemAction';
import * as images from '../../assets';
import SolutionFooter from './footer/SolutionFooter';

const Solution = props => {
  const [isSticky, setSticky] = useState(false);
  const [isMore, setMore] = useState(false);
  const ref = useRef(null);

  const handleScroll = () => {
    if (ref.current) {
      setSticky(ref.current.getBoundingClientRect().top <= 0);
    }
  };

  useEffect(() => {
    fetchSolution();
    props.actionFetchBanner();
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    }; // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (!isEmpty(props.solutions) && !isEmpty(props.activeScroll)) {
      handleDetectScroll();
    }
    // eslint-disable-next-line
  }, [props.solutions, props.activeScroll]);
  const handleDetectScroll = () => {
    let elmnt = document.getElementById('solution' + props.activeScroll);
    if (elmnt) elmnt.scrollIntoView();
  };
  const fetchSolution = () => {
    props.actionFetchSolution();
  };

  const solutionData = !isEmpty(props.solutions) ? props.solutions.content : [];
  return (
    <Fragment>
      <Header />
      <div className="banner-container">
        <img src={images.img_home_bg} alt="" className="banner-img" />
      </div>
      <TabMenu />
      <div
        className={`sticky-wrapper ${isMore ? 'sticky-more' : ''} ${
          isSticky ? 'sticky' : ''
        }`}
        ref={ref}
      >
        <SolutionFooter isMore={isMore} />
        <div className="more-container" onClick={() => setMore(!isMore)}>
          <img
            src={images.ic_arrow_black}
            alt=""
            className={`more-icon normal-icon ${isMore ? 'rorate' : ''}`}
          />
          <img
            src={images.ic_arrow_white}
            alt=""
            className={`more-icon hover-icon ${isMore ? 'rorate' : ''}`}
          />
        </div>
      </div>
      <div className="solution-container">
        {solutionData.map((item, index) => (
          <SolutionItem
            key={item.id}
            solution={item}
            evenItem={(index + 1) % 2 === 0}
            actionActiveScroll={props.actionActiveScroll}
          />
        ))}
      </div>
      <SubFooter />
      <Footer />
    </Fragment>
  );
};
export default connect(
  state => ({
    solutions: state.solution.solutions,
    banner: state.system.banner,
    activeScroll: state.system.activeScroll
  }),
  {
    actionFetchSolution,
    actionFetchBanner,
    actionActiveScroll
  }
)(Solution);
