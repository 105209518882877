import * as constants from '../../utils/constants/actionType';
import { LANG_KEY } from '../../utils/constants/constants';

const initialState = {
  locale: localStorage.getItem(LANG_KEY) || 'vi',
  banner: {},
  products: {},
  activeScroll: null
};

const system = (state = initialState, action) => {
  switch (action.type) {
    case constants.CHANGE_LANG:
      return { ...state, locale: action.payload };
    case constants.FETCH_BANNER:
      return { ...state, banner: action.payload };
    case constants.FETCH_PRODUCT:
      return { ...state, products: action.payload };
    case constants.SET_ACTIVE_SCROLL:
      return { ...state, activeScroll: action.payload };
    default:
      return state;
  }
};

export default system;
