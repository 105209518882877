import * as actionType from '../../utils/constants/actionType';
import api from '../../utils/helpers/api';

export const actionUnpublishTheme = themeId => {
  return api({
    method: 'get',
    url: '/api/v1/designer/themes/unpublish/' + themeId
  });
};

export const actionFetchSolution = params => async dispatch => {
  try {
    const { data } = await api({
      method: 'get',
      url: '/api/solution',
      params
    });
    dispatch({
      type: actionType.FETCH_SOLUTIONS,
      payload: data.data
    });
  } catch (error) {}
};

export const actionGetSolutionDetail = solutionId => {
  return api({
    method: 'get',
    url: '/api/solution/' + solutionId
  });
};
