import axios from 'axios';
import cookie from 'js-cookie';
// import { message } from 'antd';
import * as constants from '../constants/constants';
// import { getRefreshToken } from '../../view/system/systemAction';
import store from '../../configStore';

const request = axios.create({
  baseURL: constants.BASE_URL,
  headers: {
    Authorization:
      cookie.get(constants.TOKEN) && `Bearer ${cookie.get(constants.TOKEN)}`
  }
});
let isAlreadyFetchingAccessToken = false;
let subscribers = [];

// function onAccessTokenFetched(access_token) {
//   subscribers = subscribers.map(callback => callback(access_token));
//   subscribers = [];
// }

function addSubscriber(callback) {
  subscribers.push(callback);
}
request.interceptors.request.use(
  config => {
    return config;
  },
  error => Promise.reject(error)
);

request.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    const originalRequest = error.config;
    if (error.response && error.response.status === 401) {
      const refreshToken = cookie.get(constants.REFRESH_TOKEN);
      if (error.config.url.indexOf('refresh-accesstoken') !== -1) {
        subscribers = [];
        cookie.remove(constants.TOKEN);
        window.location.href = '#' + constants.routes.HOME;
      }
      if (refreshToken && !originalRequest._retry) {
        originalRequest._retry = true;
        if (!isAlreadyFetchingAccessToken) {
          isAlreadyFetchingAccessToken = true;
          // getRefreshToken({ refreshToken })
          //   .then(({ data }) => {
          //     isAlreadyFetchingAccessToken = false;

          //     cookie.set(constants.TOKEN, data.access_token, {
          //       expires: new Date(data.expires_in * 1000 + Date.now())
          //     });
          //     cookie.set(constants.REFRESH_TOKEN, data.refresh_token, {
          //       expires: new Date(
          //         constants.EXPIRED_REFRESH_TOKEN_TIME + Date.now()
          //       )
          //     });
          //     onAccessTokenFetched(data.access_token);
          //   })
          //   .catch(() => {
          //     message.error('Your session has expired.');
          //     subscribers = [];
          //     cookie.remove(constants.TOKEN);
          //     cookie.remove(constants.REFRESH_TOKEN);
          //     window.location.href = '#' + constants.routes.HOME;
          //   });
        }
        const retryOriginalRequest = new Promise(resolve => {
          addSubscriber(access_token => {
            // if (originalRequest.url.indexOf(constants.ACCOUNT_URL) !== -1) {
            //   originalRequest.headers.Authorization = `Bearer ${access_token}`;
            // }
            resolve(axios(originalRequest));
          });
        });
        return retryOriginalRequest;
      } else {
        subscribers = [];
        cookie.remove(constants.TOKEN);
        cookie.remove(constants.REFRESH_TOKEN);
        window.location.href = '#' + constants.routes.HOME;
      }
    } else {
    }
    return Promise.reject(error.response || { data: {} });
  }
);

const api = (options = {}) => {
  const { system } = store.getState();
  let params = { ...options.params, lang: system.locale || 'vi' };
  return request({
    baseURL: constants.BASE_URL,
    ...options,
    params,
    headers: {
      Authorization:
        cookie.get(constants.TOKEN) && `Bearer ${cookie.get(constants.TOKEN)}`,
      ...options.headers
    }
  });
};

export default api;
