import api from '../../utils/helpers/api';
import * as actionType from '../../utils/constants/actionType';
import * as constants from '../../utils/constants/actionType';

export const actionChangeLang = lang => ({
  type: constants.CHANGE_LANG,
  payload: lang
});

export const requestLogin = users => {
  var formData = new FormData();
  formData.append('username', users.username);
  formData.append('password', users.password);
  formData.append('grant_type', 'password');
  return api({
    method: 'post',
    url: '/oauth/token',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: 'Basic dm9sQ2xpZW50SWRQYXNzd29yZDpzZWNyZXQ='
    },
    data: formData
  });
};

export const actionFetchBanner = () => async dispatch => {
  try {
    const { data } = await api({
      method: 'get',
      url: '/api/settings/banner'
    });
    dispatch({
      type: actionType.FETCH_BANNER,
      payload: data
    });
  } catch (error) {}
};
export const actionFetchProduct = () => async dispatch => {
  try {
    const { data } = await api({
      method: 'get',
      url: '/api/products'
    });
    dispatch({
      type: actionType.FETCH_PRODUCT,
      payload: data.data
    });
  } catch (error) {}
};
export const actionActiveScroll = idSolution => ({
  type: constants.SET_ACTIVE_SCROLL,
  payload: idSolution
});
