import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Card } from 'antd';
import { FormattedMessage } from 'react-intl';
import { actionFetchContact } from './ContactAction';
import { actionActiveScroll } from '../system/systemAction';
import './Contact.scss';
import * as icons from '../../assets';

const Contact = props => {
  useEffect(() => {
    fetchContact();
    return () => {
      props.actionActiveScroll(null);
    };
    // eslint-disable-next-line
  }, []);
  const fetchContact = () => {
    props.actionFetchContact();
  };

  const contact = props.contacts.content && props.contacts.content[0];
  const phoneNumbers = contact && contact.phoneNumbers;
  const emails = contact && contact.emails;
  // const socialNetworkPages = contact && contact.socialNetworkPages;

  return (
    <div className="contact-container">
      <h1 className="contact-header">
        <FormattedMessage id="IDS_SUPPORT_WE_AVAILABLE_HELP_YOU" />
      </h1>
      <div className="card-container">
        <Card className="card-item">
          <img
            src={icons.ic_call_normal}
            className="card-icon normal-icon"
            alt=""
          />
          <img
            src={icons.ic_call_hover}
            className="card-icon hover-icon"
            alt=""
          />
          <p className="header-card">
            <FormattedMessage id="IDS_SUPPORT_CALL_US" />
          </p>
          <p className="header-card-mobile">
            <FormattedMessage id="IDS_SUPPORT_CALL_US" />
          </p>
          {phoneNumbers ? (
            phoneNumbers.map((phoneNumber, index) => (
              <h2 key={index}>{phoneNumber || '1900 23 23 89'}</h2>
            ))
          ) : (
            <h2>1900 23 23 89</h2>
          )}
          <p className="sub-des-text">
            <span>
              <FormattedMessage id="IDS_SUPPORT_24_HOURS_DAY" />
            </span>
            <br />
            <span>
              <FormattedMessage id="IDS_SUPPORT_7_DAY_WEEK" />
            </span>
          </p>
        </Card>
        {/* <Card className="card-item">
          <img
            src={icons.ic_chat_normal}
            className="card-icon normal-icon"
            alt=""
          />
          <img
            src={icons.ic_chat_hover}
            className="card-icon hover-icon"
            alt=""
          />
          <p className="header-card">
            <FormattedMessage id="IDS_SUPPORT_CHAT_WITH_US" />
          </p>
          <p className="header-card-mobile">
            <FormattedMessage id="IDS_SUPPORT_CHAT_WITH_US" />
          </p>
          <h2>Live Support</h2>
          <p className="sub-des-text">
            <span>
              <FormattedMessage id="IDS_SUPPORT_24_HOURS_DAY" />
            </span>
            <br />
            <span>
              <FormattedMessage id="IDS_SUPPORT_7_DAY_WEEK" />
            </span>
          </p>
        </Card> */}
        <Card
          className="card-item card-item-mail"
          onClick={() => window.open('mailto:care@vinsmart.net.vn')}
        >
          <img
            src={icons.ic_email_normal}
            className="card-icon normal-icon"
            alt=""
          />
          <img
            src={icons.ic_email_hover}
            className="card-icon hover-icon"
            alt=""
          />
          <p className="header-card">
            <FormattedMessage id="IDS_SUPPORT_SEND_EMAIL_TO_US" />
          </p>
          <p className="header-card-mobile">
            <FormattedMessage id="IDS_SUPPORT_SEND_EMAIL_TO_US" />
          </p>
          {emails ? (
            emails.map((email, index) => <h2 key={index}>{email}</h2>)
          ) : (
            <h2>care@vinsmart.net.vn</h2>
          )}
          <p className="sub-des-text">
            <span>
              <FormattedMessage id="IDS_SUPPORT_24_HOURS_DAY" />
            </span>
            <br />
            <span>
              <FormattedMessage id="IDS_SUPPORT_7_DAY_WEEK" />
            </span>
          </p>
        </Card>
      </div>
      <div className="card-container">
        <Card
          className="card-item card-item-social"
          onClick={() =>
            window.open(
              'https://www.facebook.com/VsmartVietnam/?brand_redir=285946388967149',
              '_blank'
            )
          }
        >
          <img
            src={icons.ic_social_normal}
            className="card-icon normal-icon"
            alt=""
          />
          <img
            src={icons.ic_social_hover}
            className="card-icon hover-icon"
            alt=""
          />
          <p className="header-card no-mid-content">
            <FormattedMessage id="IDS_SUPPORT_FOLLOW_US" />
          </p>
          <p className="header-card-mobile">
            <FormattedMessage id="IDS_SUPPORT_FOLLOW_US" />
          </p>
          {/* {socialNetworkPages ? (
            socialNetworkPages.map((socialNetworkPage, index) => (
              <p key={index} style={{ fontFamily: 'Roboto Regular' }}>
                {socialNetworkPage}
              </p>
            ))
          ) : (
            <>
              <p>@vinsmartus</p>
              <p>/VsmartVietnam</p>
            </>
          )} */}
          <>
            <div className="item-content-folow">
              <img
                src={icons.ic_tw}
                className=" normal-icon ic_fb_tw normal-icon-mobile"
                alt=""
              />
              <img
                src={icons.ic_tw_hover}
                className="hover-icon ic_fb_tw"
                alt=""
              />
              @VsmartVietnam
            </div>
            <div className="item-content-folow">
              {/* <img
                src={icons.ic_fb}
                className="normal-icon ic_fb_tw normal-icon-mobile"
                alt=""
              />
              <img
                src={icons.ic_fb_hover}
                className=" hover-icon ic_fb_tw"
                alt=""
              /> */}
              {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/VsmartVietnam */}
            </div>
          </>
        </Card>
        <Card
          className="card-item card-item-join"
          onClick={() =>
            window.open(
              'https://www.facebook.com/groups/VsmartFansClubVN',
              '_blank'
            )
          }
        >
          <img
            src={icons.ic_community_normal}
            className="card-icon normal-icon"
            alt=""
          />
          <img
            src={icons.ic_community_hover}
            className="card-icon hover-icon"
            alt=""
          />
          <p className="header-card no-mid-content">
            <FormattedMessage id="IDS_SUPPORT_JOIN_COMMUNITY" />
          </p>
          <p className="sub-des-text">
            <FormattedMessage id="IDS_SUPPORT_JOIN_OUR_COMMUNITY" />
          </p>
        </Card>
      </div>
    </div>
  );
};
export default connect(state => ({ contacts: state.contact.contacts }), {
  actionFetchContact,
  actionActiveScroll
})(Contact);
