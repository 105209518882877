import React from 'react';
import { Button, Row, Col } from 'antd';
import { FormattedMessage } from 'react-intl';
import sanitizeHtml from 'sanitize-html';
import { withRouter } from 'react-router';
import { routes, BASE_URL, optionClean } from '../../utils/constants/constants';
import './SolutionItem.scss';
import { unEscape } from '../../utils/helpers/helpers';
// import ReactPlayer from 'react-player';

const SolutionItem = (props) => {
  const solution = props.solution || {};
  const evenItem = props.evenItem || false;
  const descriptions = solution.description || [];
  // const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  return (
    <Row
      id={'solution' + solution.id}
      className="solution-item"
      style={{ flexDirection: evenItem ? 'row-reverse' : 'row' }}
    >
      <Col xs={24} sm={18} md={18} lg={10} className="left-content">
        <div className="header-content">
          <img className="sl-icon" src={BASE_URL + solution.iconUrl} alt="" />
          <div className="lb-title">{solution.solutionName}</div>
        </div>

        {descriptions.map((desc, idx) => (
          <p
            className="lb-description"
            key={idx}
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(unEscape(unescape(desc)), optionClean),
            }}
          />
        ))}
        <div className="btn-action">
          <Button
            className="btn-see-more"
            onClick={() => {
              props.history.push(`${routes.SOLUTION}-detail/${solution.id}`);
              props.actionActiveScroll(solution.id);
            }}
          >
            <FormattedMessage id="IDS_PRODUCTION_SEE_MORE" />
          </Button>
        </div>
      </Col>
      <Col xs={24} sm={24} md={24} lg={14} className="right-content">
        <div
          // className={`sl-image ${
          //   solution.solutionName === 'VOS 2.5' ? 'video-hls' : ''
          // }`}
          className="sl-image"
        >
          {/* {solution.solutionName === 'VOS 2.5' ? (
            <ReactPlayer
              url="../videos/vos/vos2.5.m3u8"
              width="100%"
              height="100%"
              playing={false}
              controls
              config={{
                file: {
                  forceHLS: !isSafari,
                  forceVideo: true,
                  hlsVersion: '0.12.4'
                }
              }}
            />
          ) : (
            <img
              src={BASE_URL + solution.imageUrl}
              alt=""
              className="img-intro-solution"
            />
          )} */}
          <img
            src={BASE_URL + solution.imageUrl}
            alt=""
            className="img-intro-solution"
          />
        </div>
      </Col>
    </Row>
  );
};
export default withRouter(SolutionItem);
