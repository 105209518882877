export const CHANGE_LANG = 'CHANGE_LANG';

export const FETCH_DATA = 'FETCH_DATA';
export const FETCH_BANNER = 'FETCH_BANNER';
export const FETCH_PRODUCT = 'FETCH_PRODUCT';
export const SET_ACTIVE_SCROLL = 'SET_ACTIVE_SCROLL';

// contact
export const FETCH_CONTACT = 'FETCH_CONTACT';

// solution
export const FETCH_SOLUTIONS = 'FETCH_SOLUTIONS';
