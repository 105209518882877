import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Layout, Menu, Dropdown, Icon } from 'antd';
import { FormattedMessage } from 'react-intl';
import './Layout.scss';
import * as icons from '../../assets';
import { routes, LANG_KEY } from '../../utils/constants/constants';
import { actionChangeLang } from '../../view/system/systemAction';
import DrawerMenu from './DrawerMenu';

const Header = (props) => {
  const [selectedItem, setSelectedItem] = useState(props.lang || 'vi');
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const gotoHome = () => {
    window.location.href = '#' + routes.SOLUTION;
  };

  const chooseDropdownItem = (key) => setSelectedItem(key);

  const handleMenuClick = ({ key }) => {
    if (key === selectedItem) return;
    chooseDropdownItem(key);
    localStorage.setItem(LANG_KEY, key);
    props.actionChangeLang(key);
    setVisibleDrawer(false);
    window.location.reload(false);
  };
  const menu = (
    <Menu onClick={handleMenuClick} selectedKeys={[selectedItem]}>
      <Menu.Item key="en">
        <FormattedMessage id="IDS_SUPPORT_ENGLISH" />
      </Menu.Item>
      <Menu.Item key="vi">
        <FormattedMessage id="IDS_SUPPORT_VIETNAMESE" />
      </Menu.Item>
    </Menu>
  );
  const isSolutionDetail =
    props.location.pathname.indexOf('/solution-detail') !== -1 ||
    props.location.pathname === routes.POLICY ||
    props.location.pathname === routes.TERM ||
    props.location.pathname === routes.VDMS;
  const isContact = props.location.pathname === routes.CONTACT;
  return (
    <Layout.Header
      className={`header-container ${
        isSolutionDetail ? 'detail-container' : ''
      }`}
    >
      <div className="block-drawer-search ">
        <img
          src={icons.ic_drawer_black}
          alt="icon drawer"
          onClick={() => setVisibleDrawer(true)}
          className="icon-cus"
          style={{
            filter: props.isVos
              ? 'invert(100%) sepia(100%) saturate(0%) hue-rotate(218deg) brightness(105%) contrast(102%)'
              : '',
          }}
        />
      </div>
      <div className="logo-pc">
        <img
          src={isContact || props.isVos ? icons.logo_white : icons.logo_black}
          alt="vinsmart"
          onClick={gotoHome}
          className="center-logo"
        />
      </div>
      <div className="logo-phone-tab">
        <img
          src={props.isVos ? icons.logo_white : icons.logo_black}
          alt="vinsmart"
          onClick={gotoHome}
          className="center-logo"
        />
      </div>
      <div className="block-drawer-search block-search">
        <img
          src={icons.ic_search_x}
          alt="icon search"
          onClick={gotoHome}
          className="icon-cus"
        />
      </div>
      <div className="header-right">
        <Dropdown overlay={menu} className="center-vertical">
          <span
            className="ant-dropdown-link"
            style={{
              color: isSolutionDetail && !props.isVos ? '#1d1d1d' : 'white',
            }}
          >
            <Icon type="global" />
            &nbsp;
            {selectedItem === 'vi' ? (
              <FormattedMessage id="IDS_SUPPORT_VIETNAMESE" />
            ) : (
              <FormattedMessage id="IDS_SUPPORT_ENGLISH" />
            )}
            &nbsp;
            <Icon type="down" />
          </span>
        </Dropdown>
      </div>
      <DrawerMenu
        visibleDrawer={visibleDrawer}
        handleCloseDrawer={() => setVisibleDrawer(false)}
        handleMenuClick={handleMenuClick}
      />
    </Layout.Header>
  );
};

export default connect(
  (state) => ({
    lang: state.system.locale,
  }),
  { actionChangeLang }
)(withRouter(Header));
