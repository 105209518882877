import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Tabs } from 'antd';
import { routes } from '../../utils/constants/constants';
import './Policy.scss';
// import Term from './Term';
import Policy from './Policy';
import injectIntl from '../../utils/helpers/intl';

const PolicyAndTerm = props => {
  const [active, setActive] = useState(props.location.pathname);
  useEffect(
    () => {
      if (active !== props.location.pathname) {
        setActive(props.location.pathname);
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.location.pathname]
  );
  return (
    <div className="policy-container">
      {/* <p className="logo-vin-account">
        <span className="first-text">Vinsmart</span>&nbsp;
        <span className="last-text">Account</span>
      </p> */}
      <div className="content-policy-container">
        <Tabs activeKey={active} onChange={key => setActive(key)}>
          <Tabs.TabPane tab={props.t('IDS_POLICY')} key={routes.POLICY}>
            <Policy />
          </Tabs.TabPane>
          {/* <Tabs.TabPane tab={props.t('IDS_TERM')} key={routes.TERM}>
            <Term />
          </Tabs.TabPane> */}
        </Tabs>
      </div>
    </div>
  );
};
export default withRouter(injectIntl(PolicyAndTerm));
