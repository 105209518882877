import React from 'react';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
// import cookie from 'js-cookie';
import { routes } from './utils/constants/constants';
import Solution from './view/solution/Solution';
import Contact from './view/contact/Contact';
import SolutionDetail from './view/solution/SolutionDetail';
import PolicyAndTerm from './view/policy/PolicyAndTerm';
import VDMS from './view/solution/VDMS';

const Routes = () => {
  return (
    <Switch>
      <Redirect exact from="/" to={routes.SOLUTION} />
      <Route exact path={routes.SOLUTION} component={Solution} />
      <Route exact path={routes.CONTACT} component={Contact} />
      <Route exact path={routes.SOLUTION_DETAIL} component={SolutionDetail} />
      <Route exact path={routes.POLICY} component={PolicyAndTerm} />
      <Route exact path={routes.VDMS} component={VDMS} />
      {/* <Route exact path={routes.TERM} component={PolicyAndTerm} /> */}
    </Switch>
  );
};

export default withRouter(Routes);
