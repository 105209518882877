import * as actionType from '../../utils/constants/actionType';

const initialState = {
  solutions: {}
};

const solution = (state = initialState, action) => {
  switch (action.type) {
    case actionType.FETCH_SOLUTIONS:
      return { ...state, solutions: action.payload };
    default:
      return state;
  }
};

export default solution;
