import React, { useState, useEffect, useCallback } from 'react';
import { CheckElementInViewport } from '../../../utils/helpers/helpers';
import { BASE_URL } from '../../../utils/constants/constants';
import './SolutionFooter.scss';

const BackTopItem = props => {
  const [isActive, setIsActive] = useState(false);
  const addEvent = useCallback(() => {
    let centerWindow = window.innerHeight / 2 + window.scrollY;
    let elm = document.getElementById('solution' + props.item.id);
    if (elm && CheckElementInViewport(elm)) {
      setIsActive(
        elm.offsetTop <= centerWindow &&
          elm.offsetTop + elm.clientHeight >= centerWindow
      );
    } else {
      setIsActive(false);
    }
    // eslint-disable-next-line
  }, [props.item.id]);

  useEffect(() => {
    addEvent();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (props.item.id) {
      window.addEventListener('scroll', addEvent);
      window.addEventListener('resize', addEvent);
    } else {
      window.removeEventListener('scroll', addEvent);
      window.removeEventListener('resize', addEvent);
    }
    // eslint-disable-next-line
  });

  useEffect(() => {
    return () => {
      window.removeEventListener('scroll', addEvent);
      window.removeEventListener('resize', addEvent);
    };
    // eslint-disable-next-line
  });

  const handleClick = e => {
    var elmnt = document.getElementById('solution' + props.item.id);
    if (elmnt)
      elmnt.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest'
      });
    props.handleClick();
    e.stopPropagation();
  };

  return (
    <div
      id={props.item.id}
      className={`ant-back-top-inner ${props.isMore ? 'more-item ' : ' '}${
        isActive ? 'active-item' : ''
      }`}
      onClick={handleClick}
    >
      {props.item.isBlank ? (
        ''
      ) : (
        <span>
          {props.isMore ? (
            <img
              src={props.item.iconUrl}
              alt=""
              className={`arrow-icon ${props.item.rotate ? 'icon-rotate' : ''}`}
            />
          ) : (
            <img
              src={BASE_URL + props.item.iconUrl}
              alt=""
              className="back-top-icon"
            />
          )}
        </span>
      )}

      <span className="item-name">{props.item.solutionName}</span>
    </div>
  );
};
export default BackTopItem;
