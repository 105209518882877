import React, { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Row, Col } from 'antd';
import sanitizeHtml from 'sanitize-html';
import { FormattedMessage } from 'react-intl';
import { actionGetSolutionDetail } from './SolutionAction';
import { download_icon } from '../../assets/index';
import { BASE_URL, optionClean } from '../../utils/constants/constants';
import { unEscape } from '../../utils/helpers/helpers';
import ReactPlayer from 'react-player';
import './Solution.scss';
import * as doc from '../../assets';
import { isEmpty } from '../../utils/helpers/helpers';

const SolutionDetail = (props) => {
  const [solutionDetail, setSolutionDetail] = useState({});

  useEffect(() => {
    fetSolutionDetail(props.match.params.detail);
    // eslint-disable-next-line
  }, []);
  const fetSolutionDetail = async (solutionId) => {
    try {
      const { data } = await actionGetSolutionDetail(solutionId);
      if (data.code === 200) {
        setSolutionDetail(data.data);
      }
    } catch (err) {}
  };

  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  const isVos = (name) => {
    return name === 'VOS 2.5';
  };
  const isGameMode = (name) => {
    return name === 'Chế độ chơi game' || name === 'Game mode';
  };
  const isVMessage = (name) => {
    return name === 'VMessage';
  };
  const isBackupAndRestore = (name) => {
    return (
      name === 'Lưu trữ và sao lưu ưu việt' ||
      name === 'Prominent Backup & Restore'
    );
  };

  const handleDownload = () => {
    let anchor = document.createElement('a');
    if (isVMessage(solutionDetail.solutionName)) {
      anchor.href = props.locale === 'en' ? doc.vmessage_en : doc.vmessage_vi;
      anchor.download =
        props.locale === 'en'
          ? 'VMessage user guide.pptx'
          : 'Hướng dẫn sử dụng Vmessage.pptx';
    } else {
      anchor.href = doc.data_transfer_vi;
      anchor.download =
        'Hướng dẫn sử dụng chuyển dữ liệu từ IOS sang Vsmart.pptx';
    }
    anchor.target = '_blank';
    anchor.click();
  };
  return (
    <div
      className={`solution-detail-container ${
        solutionDetail.solutionName === 'VOS 3.0' ? 'vos-container' : ''
      }`}
    >
      <div className="name-solution-detail">
        <span className="name">{solutionDetail.solutionName}</span>
      </div>
      {solutionDetail.content &&
        solutionDetail.content.map((el, index) => {
          if (el.type === 'row')
            return (
              <div className="first-solution-content" key={index}>
                <div className="title-solution-content">{el.title}</div>
                <div className="discription-first-solution-content">
                  {el.description.map((elD, index) => (
                    <div
                      className="item-discription-solution-content"
                      key={index}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: sanitizeHtml(
                            unEscape(unescape(elD)),
                            optionClean
                          ),
                        }}
                      />
                    </div>
                  ))}
                </div>
                <div className="image-first-solution-content">
                  <div>
                    <img
                      src={BASE_URL + el.imageUrl}
                      alt=""
                      className={`img-item-solution-content ${
                        index === 0 ? 'img-first-item' : 'sub-img-item'
                      }`}
                    />
                  </div>
                </div>
              </div>
            );
          return (
            <Row className="item-solution-content" key={index}>
              {index % 2 ? (
                <Fragment>
                  <Col xs={24} sm={24} xl={14} className="right-item-container">
                    <div>
                      <img
                        src={BASE_URL + el.imageUrl}
                        alt=""
                        className="img-item-solution-content"
                      />
                    </div>
                  </Col>
                  <Col sm={24} xs={24} xl={10}>
                    <div className="title-solution-content">{el.title}</div>
                    <div className="description-item-solution-content">
                      {el.description.map((elD, index) => {
                        const isTitle = elD.indexOf('<b>') !== -1;
                        return (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: sanitizeHtml(
                                unEscape(unescape(elD)),
                                optionClean
                              ),
                            }}
                            key={index}
                            className={`${isTitle ? 'title-item' : ''}`}
                          />
                        );
                      })}
                    </div>
                  </Col>
                </Fragment>
              ) : (
                <Fragment>
                  <Col sm={24} xs={24} xl={10}>
                    <div className="title-solution-content">{el.title}</div>
                    <div className="description-item-solution-content">
                      {el.description.map((elD, index) => {
                        const isTitle = elD.indexOf('<b>') !== -1;
                        return (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: sanitizeHtml(
                                unEscape(unescape(elD)),
                                optionClean
                              ),
                            }}
                            key={index}
                            className={`${isTitle ? 'title-item' : ''}`}
                          />
                        );
                      })}
                    </div>
                  </Col>
                  <Col xs={24} sm={24} xl={14} className="right-item-container">
                    <div>
                      <img
                        src={BASE_URL + el.imageUrl}
                        alt=""
                        className="img-item-solution-content"
                      />
                    </div>
                  </Col>
                </Fragment>
              )}
            </Row>
          );
        })}
      {!isEmpty(solutionDetail.videoUrl) && (
        <React.Fragment>
          {isVos(solutionDetail.solutionName) ||
          isGameMode(solutionDetail.solutionName) ||
          isBackupAndRestore(solutionDetail.solutionName) ? (
            <div className="detail-video-hls">
              <ReactPlayer
                url={
                  isVos(solutionDetail.solutionName)
                    ? '../videos/vos/vos2.5.m3u8'
                    : isGameMode(solutionDetail.solutionName)
                    ? '../videos/game_mode/gameMode_Vsmart.m3u8'
                    : '../videos/backup_restore/backup.m3u8'
                }
                width="100%"
                height="100%"
                playing={false}
                controls
                config={{
                  file: {
                    forceHLS: !isSafari,
                    forceVideo: true,
                    hlsVersion: '0.12.4',
                  },
                }}
              />
            </div>
          ) : (
            <div className="video-solution-detail">
              <iframe
                className="video"
                src={solutionDetail.videoUrl}
                allowFullScreen="allowfullscreen"
                name="the-iFrame"
                frameBorder="0"
                title="video"
              ></iframe>
            </div>
          )}
        </React.Fragment>
      )}

      {solutionDetail.isApp && (
        <div className="dowload-solution-detail ">
          <div className="icon-dowload-solution">
            <img src={download_icon} alt="" />
          </div>
          <div
            className="title-dowload-solution"
            onClick={() => window.open(solutionDetail.appUrl, '_blank')}
          >
            <FormattedMessage id="IDS_SUPPORT_DOWNLOAD_NOW" />
          </div>
          <div className="btn-dowload-solution">
            <Button
              className="btn-download"
              onClick={() => window.open(solutionDetail.appUrl, '_blank')}
            >
              <FormattedMessage id="IDS_SUPPORT_DOWNLOAD" />
            </Button>
          </div>
        </div>
      )}
      {(isVMessage(solutionDetail.solutionName) ||
        isBackupAndRestore(solutionDetail.solutionName)) && (
        <div className="dowload-solution-detail ">
          <div className="icon-dowload-solution">
            <img src={download_icon} alt="" />
          </div>
          <div className="title-dowload-solution" onClick={handleDownload}>
            <FormattedMessage id="IDS_SUPPORT_DOWNLOAD_NOW" />
          </div>
          <div className="btn-dowload-solution">
            <Button className="btn-download" onClick={handleDownload}>
              <FormattedMessage id="IDS_MANUAL_DOCUMENT" />
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default connect(
  (state) => ({
    locale: state.system.locale,
  }),
  null
)(SolutionDetail);
