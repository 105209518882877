import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Layout, BackTop, Icon } from 'antd';
import { withRouter } from 'react-router-dom';
import './App.scss';
import Routes from './Routes';
import Header from './components/layout/Header';
import TabMenu from './components/layout/TabMenu';
import Footer from './components/layout/Footer';
import SubFooter from './components/layout/SubFooter';
// import { isEmpty } from './utils/helpers/helpers';
import { actionFetchBanner } from './view/system/systemAction';
import * as images from './assets';
import { routes } from './utils/constants/constants';
import Solution from './view/solution/Solution';
import { actionGetSolutionDetail } from './view/solution/SolutionAction';

const App = (props) => {
  const [solutionDetail, setSolutionDetail] = useState({});
  const isSolutionDetail =
    props.location.pathname.indexOf('/solution-detail') !== -1 ||
    props.location.pathname === routes.VDMS;
  const isSolution = props.location.pathname === routes.SOLUTION;

  // const handleScroll = () => {
  //   setSticky(ref.current.getBoundingClientRect().top <= 0);
  // };
  useEffect(() => {
    props.actionFetchBanner();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (isSolutionDetail) {
      let detailInfo = props.location.pathname.split('/');
      fetSolutionDetail(detailInfo[detailInfo.length - 1]);
    }
    // eslint-disable-next-line
  }, [props.location]);
  const fetSolutionDetail = async (solutionId) => {
    try {
      const { data } = await actionGetSolutionDetail(solutionId);
      if (data.code === 200) {
        setSolutionDetail(data.data);
      }
    } catch (err) {}
  };
  if (
    props.location.pathname === routes.POLICY ||
    props.location.pathname === routes.TERM
  ) {
    return (
      <div className="app-container">
        <Header />
        <Layout>
          <Routes />
        </Layout>
        <SubFooter />
        <Footer />
      </div>
    );
  }
  if (isSolution)
    return (
      <React.Fragment>
        <Solution />
        <BackTop className="back-top-wrapper">
          <Icon type="double-left" />
        </BackTop>
      </React.Fragment>
    );
  const isVos = isSolutionDetail && solutionDetail.solutionName === 'VOS 3.0';
  return (
    <div className={`app-container ${isVos ? 'vos-container' : ''}`}>
      <Header isVos={isVos} />
      {!isSolutionDetail && (
        <div className="banner-container">
          <img
            src={
              // !isEmpty(props.banner)
              //   ? BASE_URL + props.banner.value
              //   :
              images.img_contact_bg
            }
            alt=""
            className="banner-img"
          />
        </div>
      )}
      {!isSolutionDetail && <TabMenu />}

      <Layout className="content-container">
        <Routes />
      </Layout>
      <SubFooter />
      <Footer />
      <BackTop className="back-top-wrapper">
        <Icon type="double-left" />
      </BackTop>
    </div>
  );
};

export default withRouter(
  connect((state) => ({ banner: state.system.banner }), { actionFetchBanner })(
    App
  )
);
