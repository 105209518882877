import { combineReducers } from 'redux';
import system from './system/systemReducer';
import solution from './solution/SolutionReducer';
import contact from './contact/ContactReducer';

export default combineReducers({
  system,
  solution,
  contact
});
