import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Drawer } from 'antd';
import { FormattedMessage } from 'react-intl';

import { routes, BASE_URL } from '../../utils/constants/constants';
import { isEmpty } from '../../utils/helpers/helpers';
import * as icons from '../../assets';
import './Layout.scss';

const DrawerMenu = props => {
  const handleClickSolution = item => {
    props.handleCloseDrawer();
    var elmnt = document.getElementById('solution' + item.id);
    if (elmnt)
      elmnt.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest'
      });
  };

  return (
    <Drawer
      title={
        <div>
          <img
            src={icons.ic_close}
            alt="icon drawer"
            onClick={props.handleCloseDrawer}
            className="icon-cus"
          />
        </div>
      }
      placement="left"
      closable={false}
      onClose={props.handleCloseDrawer}
      visible={props.visibleDrawer}
      className="drawer-container"
    >
      <div className="drawer-content-cus">
        {props.location.pathname === routes.SOLUTION && (
          <div className="block-solution">
            <div className="title-block-solution">
              <FormattedMessage id="IDS_SUPPORT_FIND_OUR_SOLUTIONS" />
            </div>
            <div className="list-solution">
              {!isEmpty(props.solutions) &&
                props.solutions.content.map(el => {
                  return (
                    <div
                      key={el.id}
                      className="item-solution"
                      onClick={() => handleClickSolution(el)}
                    >
                      <img
                        className="sl-icon"
                        src={BASE_URL + el.iconUrl}
                        alt=""
                      />
                      <div className="lb-title">{el.solutionName}</div>
                    </div>
                  );
                })}
            </div>
          </div>
        )}

        <div className="block-solution">
          <div className="title-block-solution">
            <FormattedMessage id="IDS_SUPPORT_CONTACT_US" />
          </div>
          <div className="list-solution">
            <div className="item-solution">
              <div className="lb-title">
                <FormattedMessage id="IDS_SUPPORT_CALL_US" />
              </div>
            </div>
            {/* <div className="item-solution">
              <div className="lb-title">
                <FormattedMessage id="IDS_SUPPORT_CHAT_WITH_US" />
              </div>
            </div> */}
            <div
              className="item-solution"
              onClick={() => {
                props.handleCloseDrawer();
                window.open('mailto:care@vinsmart.net.vn');
              }}
            >
              <div className="lb-title">
                <FormattedMessage id="IDS_SUPPORT_SEND_EMAIL_TO_US" />
              </div>
            </div>
            <div className="item-solution">
              <div className="lb-title">
                <FormattedMessage
                  id="IDS_SUPPORT_FOLLOW_US"
                  onClick={() => {
                    props.handleCloseDrawer();
                    window.open(
                      'https://www.facebook.com/VsmartVietnam/?brand_redir=285946388967149',
                      '_blank'
                    );
                  }}
                />
              </div>
            </div>
            <div
              className="item-solution"
              onClick={() => {
                props.handleCloseDrawer();
                window.open(
                  'https://www.facebook.com/groups/VsmartFansClubVN',
                  '_blank'
                );
              }}
            >
              <div className="lb-title">
                <FormattedMessage id="IDS_SUPPORT_JOIN_COMMUNITY" />
              </div>
            </div>
          </div>
        </div>
        <div className="block-solution">
          <div className="title-block-solution">
            <FormattedMessage id="IDS_LANGUAGE" />
          </div>
          <div className="list-solution">
            <div
              className="item-solution"
              onClick={() => props.handleMenuClick({ key: 'vi' })}
            >
              <div className="lb-title">
                <FormattedMessage id="IDS_SUPPORT_VIETNAMESE" />
              </div>
            </div>
            <div
              className="item-solution"
              onClick={() => props.handleMenuClick({ key: 'en' })}
            >
              <div className="lb-title">
                <FormattedMessage id="IDS_SUPPORT_ENGLISH" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default connect(
  state => ({
    solutions: state.solution.solutions
  }),
  {}
)(withRouter(DrawerMenu));
