import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Input } from 'antd';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import './Layout.scss';
import { routes } from '../../utils/constants/constants';
import * as icons from '../../assets';
import { isEmpty } from '../../utils/helpers/helpers';
import { actionFetchSolution } from '../../view/solution/SolutionAction';
import injectIntl from '../../utils/helpers/intl';

let timeout = null;
const TabMenu = props => {
  const [isFocus, setFocus] = useState(false);
  const handleChangeMenu = type => (window.location.href = '#' + type);
  // const isProduct = props.location.pathname.indexOf(routes.PRODUCT) !== -1;
  const isSolution = props.location.pathname === routes.SOLUTION;
  const isContact = props.location.pathname.indexOf(routes.CONTACT) !== -1;
  const handleSearchData = ({ target: { value } }) => {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(async () => {
      props.actionFetchSolution({
        searchText: value
      });
    }, 300);
  };
  return (
    <div className="menu-container">
      <div className={`search-input ${isFocus ? 'focused' : ''}`}>
        <span>
          <img
            src={icons.ic_search_white}
            className={`normal-icon ${isFocus ? 'focused' : ''}`}
            alt=""
          />
          <img
            src={icons.ic_search_black}
            className={`focus-icon ${isFocus ? 'focused' : ''}`}
            alt=""
          />
        </span>
        <Input
          placeholder={props.t('IDS_SUPPORT_SEARCH_SOLUTION')}
          onFocus={() => setFocus(true)}
          onBlur={e => {
            if (isEmpty(e.target.value.trim())) {
              setFocus(false);
            }
          }}
          onChange={handleSearchData}
        />
      </div>

      <div className="menu-header">
        <div
          className={`menu-item ${isSolution ? 'actived' : ''}`}
          onClick={() => handleChangeMenu(routes.SOLUTION)}
        >
          <img
            src={
              isSolution ? icons.ic_solution_active : icons.ic_solution_normal
            }
            className="tab-icon"
            alt=""
          />
          <p className="menu-item-title">
            <FormattedMessage id="IDS_SUPPORT_SOLUTIONS" />
          </p>
          <p className="menu-item-desc">
            <FormattedMessage id="IDS_SUPPORT_LOOKING_SOLUTION" />
          </p>
        </div>
        <div
          className={`menu-item ${isContact ? 'actived' : ''}`}
          onClick={() => handleChangeMenu(routes.CONTACT)}
        >
          <img
            src={isSolution ? icons.ic_contact_normal : icons.ic_contact_active}
            className="tab-icon"
            alt=""
          />
          <p className="menu-item-title">
            <FormattedMessage id="IDS_SUPPORT_CONTACT_US" />
          </p>
          <p className="menu-item-desc">
            <FormattedMessage id="IDS_SUPPORT_CONTACT_US_DES" />
          </p>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(
  connect(state => ({}), {
    actionFetchSolution
  })(withRouter(TabMenu))
);
