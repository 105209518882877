import React from 'react';
import { connect } from 'react-redux';
import { Button, Row, Col } from 'antd';
import { FormattedMessage } from 'react-intl';
import { download_icon } from '../../assets/index';
import './Solution.scss';
import * as images from '../../assets';

const vdms_url =
  'https://play.google.com/store/apps/details?id=com.vsmart.vdms.client';
const VDMS = (props) => {
  return (
    <div className="solution-detail-container">
      <div className="name-solution-detail">
        <span className="name">Quản lý điện thoại Vsmart</span>
      </div>
      <div className="first-solution-content">
        {/* <div className="title-solution-content">Quản lý điện thoại Vsmart</div> */}
        <div className="discription-first-solution-content">
          <div className="item-discription-solution-content">
            Quản lí thiết bị là tiện ích đơn giản tăng cường bảo mật thông tin
            và ngăn chặn chụp ảnh trái phép.
          </div>
          <div className="item-discription-solution-content">
            Camera và hotspot trên thiết bị sẽ bị khóa đồng thời sự kiện ra vào
            sẽ được ghi lại.
          </div>
        </div>
        <div className="image-first-solution-content">
          <div>
            <img
              src={images.vdms_1}
              alt=""
              className={`img-item-solution-content img-first-item`}
            />
          </div>
        </div>
      </div>
      <div className="first-solution-content">
        <div
          className="title-solution-content"
          style={{ color: '#2680EB', margin: '100px 0 67px 0' }}
        >
          Hướng dẫn đăng ký thông tin
        </div>
        <div className="image-first-solution-content">
          <div>
            <img
              src={images.vdms_2}
              alt=""
              className={`img-item-solution-content img-first-item`}
            />
          </div>
        </div>
        <div className="image-first-solution-content">
          <div>
            <img
              src={images.vdms_3}
              alt=""
              className={`img-item-solution-content img-first-item`}
            />
          </div>
        </div>
      </div>
      <Row className="item-solution-content">
        <Col xs={12} sm={12} xl={12}>
          <div className="title-solution-content" style={{ color: '#2680EB' }}>
            Màn hình tổng quan
          </div>
          <div className="description-item-solution-content">
            <p style={{ color: '#2680EB', margin: 0 }}>Ngoài phạm vị công ty</p>
            <p> Camera và hotspot được mở, sử dụng bình thường.</p>
            <p style={{ color: '#2680EB', margin: 0 }}>Trong phạm vi công ty</p>
            <p style={{ maxWidth: 454 }}>
              Camera và hotspot bị khóa, không sử dụng được, cố tính mở sẽ bị
              tính là vi pham qui định công ty.
            </p>
          </div>
        </Col>
        <Col sm={12} xs={12} xl={12}>
          <div>
            <img
              src={images.vdms_4}
              alt=""
              className="img-item-solution-content"
            />
          </div>
        </Col>
      </Row>
      <Row className="item-solution-content">
        <Col xs={24} sm={24} xl={14} className="right-item-container">
          <div>
            <img
              src={images.vdms_5}
              alt=""
              className="img-item-solution-content"
            />
          </div>
        </Col>
        <Col sm={24} xs={24} xl={10}>
          <div className="title-solution-content" style={{ color: '#2680EB' }}>
            Màn hình lịch sử
          </div>
          <div className="description-item-solution-content">
            Ghi lại thông tin các sự kiện ra vào, chụp ảnh của người dung.
          </div>
        </Col>
      </Row>
      <div className="dowload-solution-detail ">
        <div className="icon-dowload-solution">
          <img src={download_icon} alt="" />
        </div>
        <div
          className="title-dowload-solution"
          onClick={() => window.open(vdms_url, '_blank')}
        >
          <FormattedMessage id="IDS_SUPPORT_DOWNLOAD_NOW" />
        </div>
        <div className="btn-dowload-solution">
          <Button
            className="btn-download"
            onClick={() => window.open(vdms_url, '_blank')}
          >
            <FormattedMessage id="IDS_SUPPORT_DOWNLOAD" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default connect(
  (state) => ({
    locale: state.system.locale,
  }),
  null
)(VDMS);
