import React from 'react';
import { connect } from 'react-redux';
import './SolutionFooter.scss';
import BackTopItem from './BackTopItem';
import { isEmpty } from '../../../utils/helpers/helpers';
import injectIntl from '../../../utils/helpers/intl';

// let totalRow = 1;
const maxItemPerRow = 6;
const wrapperRef = React.createRef();

const SolutionFooter = props => {
  const convertProduct = prodList => {
    let result = [];
    prodList.forEach((el, index) => {
      let i = (index - (index % maxItemPerRow)) / maxItemPerRow;
      if (result[i]) result[i].push(el);
      else result.push([el]);
    });
    // totalRow = result.length;
    //padding blank item
    let paddedArray = [];
    let blankItem = { isBlank: true };
    result.forEach((row, rowIndex) => {
      let paddedRow = row;
      if (rowIndex !== 0) {
        for (let i = row.length; i < maxItemPerRow; i++) {
          paddedRow.push(blankItem);
        }
      }
      paddedArray.push(paddedRow);
    });
    return paddedArray;
  };
  const handleClickItem = e => {};
  return (
    <div
      ref={wrapperRef}
      className={`solution-menu ${props.isMore ? 'more-action' : 'normal'}`}
    >
      {!isEmpty(props.solutions) &&
        !isEmpty(props.solutions.content) &&
        convertProduct(props.solutions.content).map((elRow, rowIndex) => {
          return (
            <div key={rowIndex} className="back-top-container">
              {elRow.map((el, colIndex) => (
                <BackTopItem
                  item={el}
                  key={colIndex}
                  handleClick={handleClickItem}
                />
              ))}
              {/* {props.solutions.content.length > maxItemPerRow &&
                isTopRow(rowIndex) && (
                  <BackTopItem
                    isMore
                    item={{
                      iconUrl: images.ic_arrow_up,
                      solutionName: props.t('IDS_SUPPORT_MORE'),
                      rotate: moreIconRotate
                    }}
                    handleClick={handleMoreClick}
                  />
                )}
              {!isTopRow(rowIndex) && (
                <BackTopItem
                  isMore
                  item={{ isBlank: true }}
                  handleClick={handleClickItem}
                />
              )} */}
            </div>
          );
        })}
    </div>
  );
};

export default injectIntl(
  connect(
    state => ({ solutions: state.solution.solutions }),
    null
  )(SolutionFooter)
);
