import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import ScrollToTop from 'react-router-scroll-top';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import store from './configStore';
import LocaleProvider from './utils/localeProvider/LocaleProvider';

window.BUILD_INFO = process.env.BUILD_TIME + '-' + process.env.BUILD_USERNAME;

const app = (
  <Provider store={store}>
    <LocaleProvider>
      <HashRouter>
        <ScrollToTop>
          <App />
        </ScrollToTop>
      </HashRouter>
    </LocaleProvider>
  </Provider>
);
ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
