import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import './Layout.scss';
import { routes } from '../../utils/constants/constants';
import { isEmpty } from '../../utils/helpers/helpers';
import { actionFetchProduct } from '../../view/system/systemAction';

// const products = [
//   { name: 'Live', url: 'https://vsmart.net/vn-vi/live-vn' },
//   { name: 'Star', url: 'https://vsmart.net/vn-vi/star-vn' },
//   { name: 'Joy 2+', url: 'https://vsmart.net/vn-vi/joy-2plus-vn' },
//   { name: 'Bee', url: 'https://vsmart.net/vn-vi/bee-vn' },
//   { name: 'Active 1+', url: 'https://vsmart.net/vn-vi/active-1-plus-vn' },
//   { name: 'Active 1', url: 'https://vsmart.net/vn-vi/active-1-vn-2' },
//   { name: 'Joy 1+', url: 'https://vsmart.net/vn-vi/joy-1-plus-vn' },
//   { name: 'Joy 1', url: 'https://vsmart.net/vn-vi/joy-1-vn' }
// ];

const SubFooter = (props) => {
  useEffect(() => {
    fetchSolution();
    // eslint-disable-next-line
  }, []);
  const fetchSolution = () => {
    props.actionFetchProduct();
  };
  // const convertProduct = prodList => {
  //   let result = [];
  //   prodList.forEach((el, index) => {
  //     let i = (index - (index % 4)) / 4;
  //     if (result[i]) result[i].push(el);
  //     else result.push([el]);
  //   });
  //   return result;
  // };
  const convertProductMobile = (prodList) => {
    let result = [];
    prodList.forEach((el, index) => {
      let i = (index - (index % 4)) / 4;
      if (result[i]) result[i].push(el);
      else result.push([el]);
    });
    return result;
  };

  const handleAbout = (type) => {
    if (type === 'about') {
      window.open('https://vsmart.net/vn-vi', '_blank');
    } else {
      window.location.href = '#' + routes.CONTACT;
    }
  };
  return (
    <div>
      <div className="sub-footer-container" id="subFooter">
        <div className="sub-footer-left">
          <h3>
            <FormattedMessage id="IDS_SUPPORT_PRODUCTS" />
          </h3>
          {!isEmpty(props.products) &&
            // convertProduct(props.products)
            props.products.map((el, index) => (
              <div className="list-product" key={index}>
                {/* <div className="list-column">
                  <span>
                    <b>{el.round}</b>
                  </span>
                </div> */}
                {!isEmpty(el.products) &&
                  el.products.map((product) => (
                    <div className="list-column" key={product.id}>
                      <span>
                        <a
                          href={product.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {product.name}
                        </a>
                      </span>
                    </div>
                  ))}
              </div>
            ))}
        </div>
        <div className="mobile-view">
          {!isEmpty(props.products) &&
            props.products.map((el, index) => (
              <div className="list-product-mobile" key={index}>
                {/* <div className="list-column">
                  <span>
                    <b>{el.round}</b>
                  </span>
                </div> */}
                {!isEmpty(el.products) &&
                  convertProductMobile(el.products).map((item, idx) => (
                    // <tr key={idx} className="item-list-product">
                    //   {item.map(elm => (
                    //     <td key={elm.id}>{elm.name}</td>
                    //   ))}
                    // </tr>
                    <div className="list-column-row" key={idx}>
                      {item.map((elm, index) => (
                        <div className="list-column" key={index}>
                          <a
                            key={elm.id}
                            href={elm.link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {elm.name}
                          </a>
                        </div>
                      ))}
                    </div>
                  ))}
              </div>
            ))}
        </div>

        <div className="sub-footer-right">
          <h3>
            <FormattedMessage id="IDS_SUPPORT_ABOUT_US" />
          </h3>
          <div className="sub-footer-text">
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => handleAbout('about')}
              className="text-item-about-us"
            >
              <FormattedMessage id="IDS_SUPPORT_ABOUT_VINSMART" />
            </span>
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => handleAbout('contact')}
              className="text-item-about-us"
            >
              <FormattedMessage id="IDS_SUPPORT_CONTACT_US" />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(
  connect(
    (state) => ({
      products: state.system.products,
      solutions: state.solution.solutions,
    }),
    { actionFetchProduct }
  )(SubFooter)
);
