import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import './Policy.scss';

const data = [
  {
    // title: 'IDS_POLICY_PRIVACY',
    title: null,
    content: ['IDS_POLICY_INTRO_1', 'IDS_POLICY_INTRO_2'],
    sub: [
      'IDS_POLICY_LIST_1',
      'IDS_POLICY_LIST_2',
      'IDS_POLICY_LIST_3',
      'IDS_POLICY_LIST_4',
      'IDS_POLICY_LIST_5',
      'IDS_POLICY_LIST_6',
      'IDS_POLICY_LIST_7',
      'IDS_POLICY_LIST_8',
      'IDS_POLICY_LIST_9',
      'IDS_POLICY_LIST_10',
      'IDS_POLICY_LIST_11',
      'IDS_POLICY_LIST_12',
      'IDS_POLICY_LIST_13',
    ],
  },
  {
    title: 'IDS_POLICY_PERSONAL_INFO_WE_COLLECT',
    content: [
      'IDS_POLICY_PERSONAL_INFO_DES_1',
      'IDS_POLICY_PERSONAL_INFO_DES_2',
    ],
    sub: [
      'IDS_POLICY_PERSONAL_INFO_SUB_DES_1',
      'IDS_POLICY_PERSONAL_INFO_SUB_DES_2',
      'IDS_POLICY_PERSONAL_INFO_SUB_DES_3',
    ],
    content2: ['IDS_POLICY_PERSONAL_INFO_DES_3'],
  },
  {
    title: 'IDS_POLICY_USE_PERSONAL_INFO',
    content: ['IDS_POLICY_USE_PERSONAL_DES'],
    sub: [
      'IDS_POLICY_USE_PERSONAL_SUB_DES_1',
      'IDS_POLICY_USE_PERSONAL_SUB_DES_2',
      'IDS_POLICY_USE_PERSONAL_SUB_DES_3',
      'IDS_POLICY_USE_PERSONAL_SUB_DES_4',
      'IDS_POLICY_USE_PERSONAL_SUB_DES_5',
      'IDS_POLICY_USE_PERSONAL_SUB_DES_6',
      'IDS_POLICY_USE_PERSONAL_SUB_DES_7',
    ],
  },
  {
    title: 'IDS_POLICY_COLLECT_AND_USE_COOKIE',
    content: ['IDS_POLICY_COLLECT_AND_USE_COOKIE_DES_1'],
    sub: [
      'IDS_POLICY_COLLECT_AND_USE_COOKIE_SUB_DES_1',
      'IDS_POLICY_COLLECT_AND_USE_COOKIE_SUB_DES_2',
      'IDS_POLICY_COLLECT_AND_USE_COOKIE_SUB_DES_3',
      'IDS_POLICY_COLLECT_AND_USE_COOKIE_SUB_DES_4',
      'IDS_POLICY_COLLECT_AND_USE_COOKIE_SUB_DES_5',
      'IDS_POLICY_COLLECT_AND_USE_COOKIE_SUB_DES_6',
      'IDS_POLICY_COLLECT_AND_USE_COOKIE_SUB_DES_7',
    ],
    content2: [
      'IDS_POLICY_COLLECT_AND_USE_COOKIE_DES_2',
      'IDS_POLICY_COLLECT_AND_USE_COOKIE_DES_3',
      'IDS_POLICY_COLLECT_AND_USE_COOKIE_DES_3',
    ],
  },
  {
    title: 'IDS_POLICY_SHARE_PERSONAL_INFO',
    content: ['IDS_POLICY_SHARE_PERSONAL_INFO_DES'],
    sub: [
      'IDS_POLICY_SHARE_PERSONAL_INFO_SUB_DES_1',
      'IDS_POLICY_SHARE_PERSONAL_INFO_SUB_DES_2',
      'IDS_POLICY_SHARE_PERSONAL_INFO_SUB_DES_3',
      'IDS_POLICY_SHARE_PERSONAL_INFO_SUB_DES_4',
      'IDS_POLICY_SHARE_PERSONAL_INFO_SUB_DES_5',
    ],
  },
  {
    title: 'IDS_POLICY_LOCATION_PERSONAL',
    content: ['IDS_POLICY_LOCATION_PERSONAL_DES'],
  },
  {
    title: 'IDS_POLICY_SECURE_INFO',
    content: ['IDS_POLICY_SECURE_INFO_DES'],
    sub: [
      'IDS_POLICY_SECURE_INFO_SUB_DES_1',
      'IDS_POLICY_SECURE_INFO_SUB_DES_2',
    ],
  },
  {
    title: 'IDS_POLICY_ADVERTISING_AND_THIRD_PARTIES',
    content: ['IDS_POLICY_ADVERTISING_AND_THIRD_PARTIES_DES'],
  },
  {
    title: 'IDS_POLICY_ACCESS_AND_CHOICE',
    content: [
      'IDS_POLICY_ACCESS_AND_CHOICE_DES_1',
      'IDS_POLICY_ACCESS_AND_CHOICE_DES_2',
    ],
  },
  {
    title: 'IDS_POLICY_JUVENILE_PERSONAL',
    content: ['IDS_POLICY_JUVENILE_PERSONAL_DES'],
  },
  {
    title: 'IDS_POLICY_RETENTION_OF_PERSONAL',
    content: ['IDS_POLICY_RETENTION_OF_PERSONAL_DES'],
  },
  {
    title: 'IDS_POLICY_CONTACT_NOTICE_REVISION',
    content: ['IDS_POLICY_CONTACT_NOTICE_REVISION_DES_1'],
    sub: [
      'IDS_POLICY_CONTACT_NOTICE_REVISION_SUB_DES_1',
      'IDS_POLICY_CONTACT_NOTICE_REVISION_SUB_DES_2',
      'IDS_POLICY_CONTACT_NOTICE_REVISION_SUB_DES_3',
    ],
    content2: ['IDS_POLICY_CONTACT_NOTICE_REVISION_DES_2'],
  },
  {
    title: 'IDS_POLICY_ADDITIONAL_INFO_EU',
    content: [
      'IDS_POLICY_ADDITIONAL_INFO_EU_DES_1',
      'IDS_POLICY_ADDITIONAL_INFO_EU_DES_2',
      'IDS_POLICY_ADDITIONAL_INFO_EU_DES_3',
    ],
    sub: [
      'IDS_POLICY_ADDITIONAL_INFO_EU_SUB_DES_1',
      'IDS_POLICY_ADDITIONAL_INFO_EU_SUB_DES_2',
      'IDS_POLICY_ADDITIONAL_INFO_EU_SUB_DES_3',
      'IDS_POLICY_ADDITIONAL_INFO_EU_SUB_DES_4',
      'IDS_POLICY_ADDITIONAL_INFO_EU_SUB_DES_5',
    ],

    content2: ['IDS_POLICY_ADDITIONAL_INFO_EU_DES_4'],
    sub2: [
      'IDS_POLICY_ADDITIONAL_INFO_EU_SUB_DES_6',
      'IDS_POLICY_ADDITIONAL_INFO_EU_SUB_DES_7',
      'IDS_POLICY_ADDITIONAL_INFO_EU_SUB_DES_8',
      'IDS_POLICY_ADDITIONAL_INFO_EU_SUB_DES_9',
      'IDS_POLICY_ADDITIONAL_INFO_EU_SUB_DES_10',
      'IDS_POLICY_ADDITIONAL_INFO_EU_SUB_DES_11',
      'IDS_POLICY_ADDITIONAL_INFO_EU_SUB_DES_12',
      'IDS_POLICY_ADDITIONAL_INFO_EU_SUB_DES_13',
      'IDS_POLICY_ADDITIONAL_INFO_EU_SUB_DES_14',
    ],
    content3: ['IDS_POLICY_ADDITIONAL_INFO_EU_DES_5'],
  },
  {
    title: 'IDS_POLICY_EXAMPLE_INFO_COLLECTED',
    content: [
      'IDS_POLICY_EXAMPLE_INFO_COLLECTED_DES_1',
      'IDS_POLICY_EXAMPLE_INFO_COLLECTED_DES_2',
    ],
    sub: [
      'IDS_POLICY_EXAMPLE_INFO_COLLECTED_SUB_DES_1',
      'IDS_POLICY_EXAMPLE_INFO_COLLECTED_SUB_DES_2',
      'IDS_POLICY_EXAMPLE_INFO_COLLECTED_SUB_DES_3',
      'IDS_POLICY_EXAMPLE_INFO_COLLECTED_SUB_DES_4',
      'IDS_POLICY_EXAMPLE_INFO_COLLECTED_SUB_DES_5',
      'IDS_POLICY_EXAMPLE_INFO_COLLECTED_SUB_DES_6',
      'IDS_POLICY_EXAMPLE_INFO_COLLECTED_SUB_DES_7',
      'IDS_POLICY_EXAMPLE_INFO_COLLECTED_SUB_DES_8',
      'IDS_POLICY_EXAMPLE_INFO_COLLECTED_SUB_DES_9',
    ],

    content2: ['IDS_POLICY_EXAMPLE_INFO_COLLECTED_DES_3'],
    sub2: [
      'IDS_POLICY_EXAMPLE_INFO_COLLECTED_SUB_DES_10',
      'IDS_POLICY_EXAMPLE_INFO_COLLECTED_SUB_DES_11',
      'IDS_POLICY_EXAMPLE_INFO_COLLECTED_SUB_DES_12',
      'IDS_POLICY_EXAMPLE_INFO_COLLECTED_SUB_DES_13',
      'IDS_POLICY_EXAMPLE_INFO_COLLECTED_SUB_DES_14',
      'IDS_POLICY_EXAMPLE_INFO_COLLECTED_SUB_DES_15',
      'IDS_POLICY_EXAMPLE_INFO_COLLECTED_SUB_DES_16',
      'IDS_POLICY_EXAMPLE_INFO_COLLECTED_SUB_DES_17',
      'IDS_POLICY_EXAMPLE_INFO_COLLECTED_SUB_DES_18',
      'IDS_POLICY_EXAMPLE_INFO_COLLECTED_SUB_DES_19',
    ],

    content3: [
      'IDS_POLICY_EXAMPLE_INFO_COLLECTED_DES_4',
      'IDS_POLICY_EXAMPLE_INFO_COLLECTED_DES_5',
    ],
    sub3: [
      'IDS_POLICY_EXAMPLE_INFO_COLLECTED_SUB_DES_20',
      'IDS_POLICY_EXAMPLE_INFO_COLLECTED_SUB_DES_21',
      'IDS_POLICY_EXAMPLE_INFO_COLLECTED_SUB_DES_22',
      'IDS_POLICY_EXAMPLE_INFO_COLLECTED_SUB_DES_23',
    ],

    content4: ['IDS_POLICY_EXAMPLE_INFO_COLLECTED_DES_6'],
    sub4: [
      'IDS_POLICY_EXAMPLE_INFO_COLLECTED_SUB_DES_24',
      'IDS_POLICY_EXAMPLE_INFO_COLLECTED_SUB_DES_25',
      'IDS_POLICY_EXAMPLE_INFO_COLLECTED_SUB_DES_26',
      'IDS_POLICY_EXAMPLE_INFO_COLLECTED_SUB_DES_27',
      'IDS_POLICY_EXAMPLE_INFO_COLLECTED_SUB_DES_28',
      'IDS_POLICY_EXAMPLE_INFO_COLLECTED_SUB_DES_29',
      'IDS_POLICY_EXAMPLE_INFO_COLLECTED_SUB_DES_30',
      'IDS_POLICY_EXAMPLE_INFO_COLLECTED_SUB_DES_31',
      'IDS_POLICY_EXAMPLE_INFO_COLLECTED_SUB_DES_32',
    ],

    content5: [
      'IDS_POLICY_EXAMPLE_INFO_COLLECTED_DES_7',
      'IDS_POLICY_EXAMPLE_INFO_COLLECTED_DES_8',
    ],
    sub5: [
      'IDS_POLICY_EXAMPLE_INFO_COLLECTED_SUB_DES_33',
      'IDS_POLICY_EXAMPLE_INFO_COLLECTED_SUB_DES_34',
      'IDS_POLICY_EXAMPLE_INFO_COLLECTED_SUB_DES_35',
      'IDS_POLICY_EXAMPLE_INFO_COLLECTED_SUB_DES_36',
    ],
  },
];
const Policy = () => (
  <div className="tab-content">
    {data.map((el, index) => (
      <div key={index}>
        <b>{el.title && <FormattedMessage id={el.title} />}</b>
        {el.content &&
          el.content.map((item, i) => (
            <p style={{ textAlign: 'justify' }} key={i}>
              <FormattedHTMLMessage id={item} />
            </p>
          ))}
        {el.sub && (
          <ul>
            {el.sub.map((element, id) => (
              <li style={{ textAlign: 'justify' }} key={id}>
                <FormattedHTMLMessage id={element} />
              </li>
            ))}
          </ul>
        )}

        {el.content2 &&
          el.content2.map((item, i) => (
            <p style={{ textAlign: 'justify' }} key={i}>
              <FormattedHTMLMessage id={item} />
            </p>
          ))}
        {el.sub2 && (
          <ul>
            {el.sub2.map((element, id) => (
              <li style={{ textAlign: 'justify' }} key={id}>
                <FormattedHTMLMessage id={element} />
              </li>
            ))}
          </ul>
        )}

        {el.content3 &&
          el.content3.map((item, i) => (
            <p style={{ textAlign: 'justify' }} key={i}>
              <FormattedHTMLMessage id={item} />
            </p>
          ))}
        {el.sub3 && (
          <ul>
            {el.sub3.map((element, id) => (
              <li style={{ textAlign: 'justify' }} key={id}>
                <FormattedHTMLMessage id={element} />
              </li>
            ))}
          </ul>
        )}

        {el.content4 &&
          el.content4.map((item, i) => (
            <p style={{ textAlign: 'justify' }} key={i}>
              <FormattedHTMLMessage id={item} />
            </p>
          ))}
        {el.sub4 && (
          <ul>
            {el.sub4.map((element, id) => (
              <li style={{ textAlign: 'justify' }} key={id}>
                <FormattedHTMLMessage id={element} />
              </li>
            ))}
          </ul>
        )}

        {el.content5 &&
          el.content5.map((item, i) => (
            <p style={{ textAlign: 'justify' }} key={i}>
              <FormattedHTMLMessage id={item} />
            </p>
          ))}
        {el.sub5 && (
          <ul>
            {el.sub5.map((element, id) => (
              <li style={{ textAlign: 'justify' }} key={id}>
                <FormattedHTMLMessage id={element} />
              </li>
            ))}
          </ul>
        )}
      </div>
    ))}
  </div>
);
export default Policy;
